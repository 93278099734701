import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/future/image';
import ImageNext from 'next/image';
import classnames from 'classnames';
import { Carousel } from 'react-responsive-carousel';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NumericFormat } from 'react-number-format';

import {
  Tabs,
  Tab,
  GameButton,
  Button,
  List,
  ListItem,
  Jackpot,
  Last,
  Accordion,
  Chip,
  Skeleton,
  Pagination,
  OutAmountItem,
  JackpotItem,
  ItemListSchemaOrg,
  RunningLine,
} from 'bf-ui';

import styles from '/src/assets/styles/pages/home.page.scss';

import { getCategories_req, getSlider_req } from '../../../api/home.api';
import { getGames_req } from '../../../api/game.api';

import websiteConfig from '../../../configs/websiteConfig';

import NoGameImg from '../../../assets/images/additional/no-game-image.jpg';
import CupImg from '../../../assets/images/icons/cup.png';

import { setCategories } from '../../../redux/global.slice';
import LinkWrapper from '../../wrappers/link/link.wrapper';

const CATEGORY_KEYS = {
  PROVIDERS: 'providers',
};

const shimmer = (w, h) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#293746" offset="20%" />
      <stop stop-color="#1c2630" offset="50%" />
      <stop stop-color="#293746" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#293746" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str);

export default function HomeSection(props) {
  const router = useRouter();
  const { locale } = router;
  const intl = useIntl();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { isMobile, categories, operators, providers } = useSelector(
    (state) => state.global
  );

  const { jackpots, winners, withdraws, winnersBig } = useSelector(
    (state) => state.recent
  );

  const { gamesOnPage } = props;

  const [gamesLoading, setGamesLoading] = useState(false);
  const [sliderData, setSliderData] = useState(props.sliderData);
  const [gamesCount, setGamesCount] = useState(props?.games?.count || 0);
  const [games, setGames] = useState(props?.games?.items);

  const [params, setParams] = useState({
    providerName: props.providerName,
    page: props.page - 1,
    categoryIndex:
      categories?.findIndex((category) => category.Key === props.category) || 0,
  });

  const { providerName, page, categoryIndex } = params;

  const [lastActiveTab, setLastActiveTab] = useState(0);
  const [winnersSnapshot, setWinnersSnapshot] = useState(null);

  const loadSliderData = useCallback(async () => {
    try {
      const response = await getSlider_req({
        Key: 'home_slider',
        IsMobile: isMobile,
        culture: locale,
      });

      setSliderData(response);
    } catch (e) {
      console.error('HomeSection -> loadSliderData Error:', e);
    }
  }, []);

  const loadGames = useCallback(async () => {
    try {
      setGamesLoading(true);
      const categoryId = categories?.[categoryIndex]?.ID;

      if (!categoryId) {
        return;
      }

      let providerId = null;

      if (
        providerName &&
        categories[categoryIndex]?.Key === CATEGORY_KEYS.PROVIDERS
      ) {
        providerId = providers.find(
          (provider) => provider.Name === providerName
        )?.ID;
      }

      const response = await getGames_req(
        {},
        isMobile ? 24 : 28,
        page + 1,
        providerId,
        categoryId,
        null,
        isMobile
      );

      setGamesCount(response?.count || 1);
      setGames(response?.items ? response.items : null);
    } catch (e) {
      console.error('HomeSection -> loadGames Error:', e);
    } finally {
      setGamesLoading(false);
    }
  }, [categories, categoryIndex, providerName, page]);

  const onCategoryTabChange = useCallback((index) => {
    const categoryKey = categories?.[categoryIndex]?.Key;

    if (!categoryKey) {
      return;
    }
    setParams({
      providerName:
        categoryKey === CATEGORY_KEYS.PROVIDERS ? providers?.[0].Name : '',
      page: 0,
      categoryIndex: index,
    });
  }, []);

  const onPageChange = useCallback(
    ({ selected }) => {
      setParams({ ...params, page: selected });

      const categoryKey = categories?.[categoryIndex].Key;

      const providerQuery =
        providerName && categoryKey === CATEGORY_KEYS.PROVIDERS
          ? `&provider=${providerName}`
          : '';

      const href = `/${categoryKey}/?page=${selected + 1}${providerQuery}`;

      router.push(href, href);
    },
    [categories, categoryIndex, providerName]
  );

  const onPlay = useCallback((key) => {
    router.push(`/games/${key}?game-type=classic`, `/games/${key}`);
  }, []);

  const onPractice = useCallback((key) => {
    router.push(`/games/${key}?game-type=demo`, `/games/${key}`);
  }, []);

  const getCategories = useCallback(async () => {
    try {
      const response = await getCategories_req({}, locale);
      if (response) {
        dispatch(setCategories(response));
      }
    } catch (e) {
      console.error('HomeSection -> getCategories Error: ', e);
    }
  }, [user, locale]);

  const pageHrefBuilder = useCallback(
    (selected) => {
      const categoryKey = categories?.[categoryIndex].Key;

      const providerQuery =
        providerName && categoryKey === CATEGORY_KEYS.PROVIDERS
          ? `&provider=${providerName}`
          : '';

      return `/${categoryKey}/?page=${selected}${providerQuery}`;
    },
    [categories, categoryIndex, providerName]
  );

  const takeSnapshotFromWinners = useCallback(() => {
    if (!winnersSnapshot && winners.length) {
      setWinnersSnapshot(
        [...winners].map((data) => {
          try {
            const splitted = data?.Key?.split('-');
            return {
              ...data,
              name: splitted
                .slice(0, splitted.length - 1)
                .join(' ')
                .slice(0, 100),
            };
            // eslint-disable-next-line no-empty
          } catch (e) {}

          return data;
        })
      );
    }
  }, [winners, winnersSnapshot]);

  useEffect(() => {
    if (!sliderData) {
      loadSliderData();
    }

    if (!games) {
      loadGames();
    }
  }, []);

  useEffect(() => {
    getCategories();
  }, [user?.id, locale]);

  useEffect(() => {
    loadGames();
  }, [categoryIndex, providerName, page]);

  useEffect(() => {
    takeSnapshotFromWinners();
  }, [winners]);

  useEffect(() => {
    if (!user?.id && router?.asPath === '/my-games') {
      router.push('/', '/');
    } else if (router?.asPath === '/crash-games') {
      const categoryIndexNew = categories.findIndex(
        (data) => data.Key === 'crash-games'
      );

      if (categoryIndex !== -1) {
        setParams({ ...params, categoryIndex: categoryIndexNew });
      }
    }
  }, [user?.id, router?.asPath]);

  useEffect(() => {
    if (router?.query?.category === 'providers' && router?.query?.provider) {
      let categoryIndexNew = -1;

      if (categories?.length) {
        categoryIndexNew = categories.findIndex(
          (data) => data.Key === router?.query?.category
        );
      }

      const newParams = {
        ...params,
        providerName: router.query.provider,
        page: router.query.page ? parseInt(router.query.page, 10) - 1 : 0,
      };

      if (categoryIndex > 0) {
        newParams.categoryIndex = categoryIndexNew;
      }

      setParams(newParams);
    }
  }, [router.asPath]);

  useEffect(() => {
    const category = router?.query?.category;
    const currentCategory = categories?.[categoryIndex];

    if (category === 'my-games' && currentCategory?.Key !== 'my-games') {
      const neededIndex = categories.findIndex(
        (item) => item.Key === 'my-games'
      );

      if (neededIndex > -1) {
        setParams({ ...params, categoryIndex: neededIndex });
      }
    }
  }, [router.asPath]);

  const staticSliderData = sliderData?.length
    ? sliderData.filter((slide) => slide.IsStatic)[0] || null
    : null;

  const notStaticSliderData = sliderData?.length
    ? sliderData.filter((slide) => !slide.IsStatic)[0] || null
    : null;

  const notStaticSliderComponent =
    notStaticSliderData?.List?.map((slide) => {
      const imgComponent = (
        <ImageNext
          src={`${websiteConfig.cdnUrl}/${slide.Path}`}
          alt={slide.Title}
          key={slide.Title}
          width={400}
          height={isMobile ? 400 * 0.3575 : 400 * 0.535}
          placeholder="blur"
          blurDataURL={`data:image/svg+xml;base64,${toBase64(
            shimmer(400, isMobile ? 400 * 0.3575 : 400 * 0.535)
          )}`}
        />
      );

      if (slide.Href) {
        return (
          <LinkWrapper
            href={slide.Href}
            as={slide.As}
            key={slide.Title}
            target={slide.Target}
            className="carousel_link"
          >
            {imgComponent}
          </LinkWrapper>
        );
      }

      return imgComponent;
    }) || [];

  const providerChips = providers?.map((provider) => (
    <LinkWrapper
      key={provider.Name}
      href={`/providers/?provider=${provider.Name}`}
    >
      <Chip label={provider.Name} selected={providerName === provider.Name} />
    </LinkWrapper>
  ));

  return (
    <>
      <div id="home-page" className="page">
        {isMobile && (
          <div className="carousel_wrapper show_mobile">
            {notStaticSliderData?.List ? (
              <Carousel
                autoPlay
                infiniteLoop
                stopOnHover
                swipeable
                interval={4000}
                showThumbs={false}
                showStatus={false}
                showArrows={false}
                showIndicators={false}
              >
                {notStaticSliderComponent}
              </Carousel>
            ) : (
              <></>
            )}
          </div>
        )}
        {categories?.length ? (
          <div className="tabs_wrapper">
            <Tabs shine value={categoryIndex} onChange={onCategoryTabChange}>
              {categories.map((category) => (
                <div key={category.Key}>
                  <LinkWrapper href={`/${category.Key}`}>
                    <Tab>
                      <img
                        src={`${websiteConfig.cdnUrl}${category.Icon}`}
                        alt={category.Name}
                        width={27}
                        height={27}
                      />
                      <span>{category.Name}</span>
                      {category.StickerTitle && (
                        <div
                          className="label_wrapper"
                          style={{
                            border: category.StickerBorderColor
                              ? `1px solid ${category.StickerBorderColor}`
                              : undefined,
                            color: category.StickerTextColor,
                            background: category.StickerColor,
                          }}
                        >
                          {category.StickerTitle}
                        </div>
                      )}
                    </Tab>
                  </LinkWrapper>
                </div>
              ))}
            </Tabs>
          </div>
        ) : (
          <></>
        )}
        {isMobile && winnersSnapshot?.length ? (
          <RunningLine>
            <div className="winners_line_content">
              {winnersSnapshot.map((winner) => (
                <div className="single_win">
                  <Image
                    src={CupImg}
                    width={15}
                    height={14}
                    alt="Recent winners cup"
                  />
                  <span>
                    {winner.Phone} won {winner.Currency}{' '}
                    <NumericFormat
                      value={winner.Amount}
                      className="winner_line_amount"
                      displayType="text"
                      thousandSeparator
                      renderText={(value, renderProps) => (
                        <span {...renderProps}>{value || '0'}</span>
                      )}
                    />
                    {winner.name && ' - '}
                    {winner.name && (
                      <LinkWrapper href={`/games/${winner.Key}`}>
                        {winner.name}
                      </LinkWrapper>
                    )}
                  </span>
                </div>
              ))}
            </div>
          </RunningLine>
        ) : (
          <></>
        )}
        {categories?.[categoryIndex]?.Key === CATEGORY_KEYS.PROVIDERS &&
        providers?.length ? (
          <div className="providers_wrapper">
            {isMobile ? <Accordion>{providerChips}</Accordion> : providerChips}
          </div>
        ) : (
          <></>
        )}

        <div
          className={classnames(
            'home_content',
            categories?.[categoryIndex]?.Key !== CATEGORY_KEYS.PROVIDERS &&
              'without_providers'
          )}
        >
          {!isMobile && (
            <div className="sidebar left show_desktop">
              {jackpots?.length ? (
                <Jackpot name={intl.messages.jackpot}>
                  <List>
                    {jackpots.map((data) => (
                      <JackpotItem
                        key={data.ID}
                        name={data.Name}
                        amount={data.Amount}
                        currency={websiteConfig.currency}
                        lastWinDate={data.LastWinDate}
                        lastWinStartLabel="Last Win:"
                        lastWinPhone={data.LastWinPhone}
                      />
                    ))}
                  </List>
                </Jackpot>
              ) : (
                <></>
              )}

              <Last
                name={intl.messages.winners}
                actions={
                  <>
                    <Button
                      variant={lastActiveTab === 0 ? 'contained' : 'contained2'}
                      onClick={() => setLastActiveTab(0)}
                    >
                      {intl.messages.big}
                    </Button>
                    <Button
                      variant={lastActiveTab === 1 ? 'contained' : 'contained2'}
                      onClick={() => setLastActiveTab(1)}
                    >
                      {intl.messages.recent}
                    </Button>
                  </>
                }
              >
                <List>
                  {lastActiveTab === 0 &&
                    winnersBig.map((data) => (
                      <ListItem key={data.WinID}>
                        {data.Key ? (
                          <LinkWrapper href={`/games/${data.Key}`}>
                            <OutAmountItem
                              title={data.Phone}
                              amount={data.Amount}
                              currency={data.Currency}
                              image={`${websiteConfig.cdnUrl}${data.Media}`}
                            />
                          </LinkWrapper>
                        ) : (
                          <OutAmountItem
                            title={data.Phone}
                            amount={data.Amount}
                            currency={data.Currency}
                            image={`${websiteConfig.cdnUrl}${data.Media}`}
                          />
                        )}
                      </ListItem>
                    ))}
                  {lastActiveTab === 1 &&
                    winners.map((data) => (
                      <ListItem key={data.WithdrawID}>
                        <LinkWrapper href="/withdraw" privateRoute>
                          <OutAmountItem
                            title={data.Phone}
                            amount={data.Amount}
                            currency={data.Currency}
                            image={`${websiteConfig.cdnUrl}${data.Media}`}
                          />
                        </LinkWrapper>
                      </ListItem>
                    ))}
                </List>
              </Last>
            </div>
          )}
          {gamesLoading ? (
            <div className="games_wrapper">
              <div className="games_wrapper_inner">
                {new Array(20).fill().map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Skeleton key={index} />
                ))}
              </div>
            </div>
          ) : (
            <>
              {games?.length ? (
                <div className="games_wrapper">
                  <div className="games_wrapper_inner">
                    {games.map((gameData) => (
                      <GameButton
                        key={gameData.Name}
                        NextImage={
                          <Image
                            src={
                              gameData.ImageWebP &&
                              !gameData.ImageWebP.includes('http')
                                ? websiteConfig.cdnUrl + gameData.ImageWebP
                                : gameData.ImageWebP
                            }
                            placeholder="blur"
                            blurDataURL={`data:image/svg+xml;base64,${toBase64(
                              shimmer(isMobile ? 128 : 280, isMobile ? 86 : 190)
                            )}`}
                            width={isMobile ? 128 : 280}
                            height={isMobile ? 86 : 190}
                            priority
                            className="game_img"
                            alt={gameData.Name}
                          />
                        }
                        noImage={NoGameImg.src}
                        withoutName={!isMobile}
                        name={gameData.Name}
                        linkComponent={LinkWrapper}
                        withoutInnerLink
                        href={`/games/${gameData.Key}`}
                        as={`/games/${gameData.Key}`}
                        instantPlay
                        square
                        bouncingText={gameData.Label}
                        labelText={gameData.StickerTitle}
                        labelTopColor={gameData.StickerColor}
                        labelBottomColor={
                          gameData.StickerBorderColor || gameData.StickerColor
                        }
                        newLabel
                        onClick={() => {
                          if (isMobile) {
                            router.push(
                              `/games/${gameData.Key}`,
                              `/games/${gameData.Key}`
                            );
                          }
                        }}
                        onPlay={
                          gameData.InitUrl
                            ? () => onPlay(gameData.Key)
                            : undefined
                        }
                        onPractice={
                          gameData.InitDemoUrl
                            ? () => onPractice(gameData.Key)
                            : undefined
                        }
                      />
                    ))}
                  </div>
                  <Pagination
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={1}
                    pageCount={Math.ceil(gamesCount / gamesOnPage)}
                    previousLabel={intl.messages.prev}
                    onPageChange={onPageChange}
                    forcePage={page}
                    hrefBuilder={pageHrefBuilder}
                  />
                </div>
              ) : (
                <p className="no_games">{intl.messages.no_games}</p>
              )}
            </>
          )}
          {!isMobile && (
            <div className="sidebar right">
              {operators?.length ? (
                <div className="operators_wrapper">
                  <h3>{intl.messages.paybill_numbers}</h3>
                  <List>
                    {operators.map((operatorData) => (
                      <ListItem key={operatorData.Name.concat(operatorData.ID)}>
                        <Image
                          placegolder="blur"
                          src={`${websiteConfig.cdnUrl}/api/v1/media/${operatorData.MediaID}`}
                          width={104}
                          height={27}
                          alt={operatorData.Name}
                        />
                        <div className="paybill_account_number">
                          <LinkWrapper href="/deposit" privateRoute>
                            <Button variant="contained">
                              {intl.messages.deposit}
                            </Button>
                          </LinkWrapper>
                        </div>
                      </ListItem>
                    ))}
                  </List>
                </div>
              ) : (
                <></>
              )}
              {notStaticSliderData?.List ? (
                <div className="carousel_wrapper">
                  <Carousel
                    autoPlay
                    infiniteLoop
                    stopOnHover
                    swipeable
                    interval={4000}
                    showThumbs={false}
                    showStatus={false}
                    showArrows={false}
                    showIndicators={false}
                  >
                    {notStaticSliderComponent}
                  </Carousel>
                </div>
              ) : (
                <></>
              )}
              {staticSliderData?.List ? (
                <div className="static_slides">
                  {staticSliderData.List.map((slide) => {
                    const ImgComponent = (
                      <div className="static_slider_image_wrapper">
                        <ImageNext
                          src={`${websiteConfig.cdnUrl}${slide.Path}`}
                          alt={slide.Title}
                          key={slide.Title}
                          width={400}
                          height={400 * 0.72}
                          placeholder="blur"
                          blurDataURL={`data:image/svg+xml;base64,${toBase64(
                            shimmer(400, 400 * 0.72)
                          )}`}
                        />
                      </div>
                    );

                    if (slide.Href) {
                      return (
                        <LinkWrapper
                          href={slide.Href}
                          as={slide.As}
                          key={slide.Title}
                        >
                          {ImgComponent}
                        </LinkWrapper>
                      );
                    }

                    return ImgComponent;
                  })}
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
        {gamesCount && games ? (
          <ItemListSchemaOrg
            url={websiteConfig.url}
            numberOfItems={gamesCount}
            items={games.map((game) => ({
              name: game.Name,
              image: (game.Image || '').includes('http')
                ? game.Image
                : process.env.NEXT_PUBLIC_BASE_URL_V1 + game.Image,
              url: `${websiteConfig.url}/games/${game.Key}`,
              price: 0,
              currency: websiteConfig.currency,
            }))}
          />
        ) : (
          <></>
        )}
      </div>
      <style jsx>{styles}</style>
    </>
  );
}
